import firebase from "firebase/app";
import firebaseAuth from "firebase/auth";

window.addEventListener("DOMContentLoaded", () => {
  const modal = document.getElementById("browserSupport");
  const footer = document.getElementById("footer");
  const logout = document.getElementById("logout");

  const objAgent = navigator.userAgent;
  let browserName = navigator.appName;
  const vendor = navigator.vendor;
  let versionNumber = "" + parseFloat(navigator.appVersion);
  let offSetVersion;

  const isInternetExplorer =
    objAgent.indexOf("MSIE") !== -1 || objAgent.indexOf("Trident/") !== -1;
  const isFirefox = objAgent.indexOf("Firefox") !== -1;
  const isChrome =
    objAgent.indexOf("Chrome") !== -1 && vendor === "Google Inc.";
  const isSafari =
    objAgent.indexOf("Safari") !== -1 && vendor === "Apple Computer, Inc.";
  const isEdge = objAgent.indexOf("Edge") !== -1;
  const isOpera = objAgent.indexOf("OP") !== -1;

  // In Chrome
  if (isChrome) {
    offSetVersion = objAgent.indexOf("Chrome");
    browserName = "Chrome";
    versionNumber = objAgent.substring(offSetVersion + 7).slice(0, 4);
  }

  // In Firefox
  if (isFirefox) {
    offSetVersion = objAgent.indexOf("Firefox");
    browserName = "Firefox";
    versionNumber = objAgent.substring(offSetVersion + 8).slice(0, 4);
  }

  // In Internet Explorer
  if (isInternetExplorer) {
    offSetVersion = objAgent.indexOf("MSIE");
    browserName = "Microsoft Internet Explorer";
    // When IE version is 11 userAgent is different so handling it in the ternary
    versionNumber =  objAgent.indexOf("Trident/") !== -1 ? 11 : objAgent.split('msie')[1];
  }

  // In Safari
  if (isSafari) {
    offSetVersion = objAgent.indexOf("Safari");
    browserName = "Safari";
    versionNumber = objAgent.substring(offSetVersion + 7).slice(0, 4);
  }

  // In Edge
  if (isEdge) {
    offSetVersion = objAgent.indexOf("Edge") || objAgent.indexOf("Trident/");
    browserName = "Edge";
    versionNumber = objAgent.substring(offSetVersion + 5).slice(0, 4);
  }

  // In Opera
  if (isOpera) {
    offSetVersion = objAgent.indexOf("OP");
    browserName = "Opera";
    versionNumber = objAgent.substring(offSetVersion + 4).slice(0, 4);
  }

  const checkVersion = parseInt(versionNumber, 10);
  const CheckEdgeVersion = checkVersion < 79;

  if (isInternetExplorer || isOpera || (isEdge && CheckEdgeVersion)) {
    modal.style.display = "block";
  }

  document.getElementById("browserName").innerHTML = browserName;
  document.getElementById("versionNumber").innerHTML = `(v.${versionNumber})`;

  const closeModal = () => {
    modal.style.display = "none";
  }

  document.getElementById("closeIcon").addEventListener("click", closeModal);
  document.getElementById("continueToApp").addEventListener("click", closeModal);

  const logoutUser = () => {
    try {
      firebase.auth().signOut();
      modal.style.display = "none";
    } catch (e) {
      console.log(e);
    }
  };

  logout.addEventListener("click", logoutUser);

  if (isInternetExplorer) {
    footer.style.display = "none";
  }
  // Display logout button if user is logged-in else disply nothing
  try {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        footer.style.display = "block";
      } else {
        footer.style.display = "none";
        // No user is signed in.
      }
    });
  } catch (e) {
    console.log(e);
  }
});
